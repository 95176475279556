import {
    Box, HoverCard,
    IconButton,
    Link, Text
} from "@radix-ui/themes";

export function Icons() {
    return (
        <Box>
            <Box width="64px" height="64px">
                <HoverCard.Root>
                    <HoverCard.Trigger>
                        <Link href="#">
                            <IconButton>
                                <i class="fa-duotone fa-xl fa-building-magnifying-glass" />
                            </IconButton>
                        </Link>
                    </HoverCard.Trigger>
                    <HoverCard.Content size="2" maxWidth="320px">
                        <Text as="div" size="2" trim="both">
                            At Work
                        </Text>
                    </HoverCard.Content>
                </HoverCard.Root>
            </Box>
            <Box width="64px" height="64px">
                <HoverCard.Root>
                    <HoverCard.Trigger>
                        <Link href="#">
                            <IconButton>
                                <i class="fa-duotone fa-xl fa-space-station-moon" />
                            </IconButton>
                        </Link>
                    </HoverCard.Trigger>
                    <HoverCard.Content size="2" maxWidth="320px">
                        <Text as="div" size="2" trim="both">
                            Goal: Death Star
                        </Text>
                    </HoverCard.Content>
                </HoverCard.Root>
            </Box>
            <Box width="64px" height="64px">
                <HoverCard.Root>
                    <HoverCard.Trigger>
                        <Link href="#">
                            <IconButton>
                                <i class="fa-duotone fa-list-check" />
                            </IconButton>
                        </Link>
                    </HoverCard.Trigger>
                    <HoverCard.Content size="2" maxWidth="320px">
                        <Text as="div" size="2" trim="both">
                            Current Task: Find the rebels
                        </Text>
                    </HoverCard.Content>
                </HoverCard.Root>
            </Box>
        </Box>
    );
}
