import {
  ApolloClient,
  ApolloProvider,
  InMemoryCache,
  gql,
} from "@apollo/client";
import {
  Section,
  Theme
} from "@radix-ui/themes";
import "@radix-ui/themes/styles.css";
import "./App.css";
import { CurrentTime } from "./components/CurrentTime";
import "./fontawesome-pro-6.5.2-web/css/all.min.css";
import AuthdView from "./views/AuthdView";
import UnAuthdView from "./views/UnAuthdView";



// let _auth = localStorage.getItem("authentication") || undefined;
let viewerId;

let _client = new ApolloClient({
  uri: "/graphql",
  cache: new InMemoryCache(),
  headers: {
    "authorization": localStorage.getItem("authentication"),
    "x-datawhore-token": localStorage.getItem("x-datawhore-token")
  }
});

const REGISTER_AUTH = gql`
  mutation {
    registerAuth
  }
`;

if (!localStorage.getItem("authentication")) {
  await _client.mutate({ mutation: REGISTER_AUTH }).then((result) => {
    const response = result.data.registerAuth;
    const tokens = response.length ? response.split(":") : [response];
    localStorage.setItem("x-datawhore-token", tokens[1]);
    localStorage.setItem("authentication", tokens[0]);
    console.log(result, tokens)
    const [_, body] = String(result.data.registerAuth).split(".");
    const { viewId } = JSON.parse(atob(body))
    // _auth = tokens[1];
    localStorage.setItem("viewerId", viewId || "");
  });
}
_client = undefined;

const client = new ApolloClient({
  uri: "/graphql",
  cache: new InMemoryCache(),
  headers: { viewerId, "authorization": localStorage.getItem("authentication"), "x-datawhore-token": localStorage.getItem("x-datawhore-token") },
});

function App() {
  const loggedIn = localStorage.getItem('viewerId') ?? [];
  // const loggedIn = ""
  return (
    <Theme appearance="dark" accentColor="pink" radius="large" scaling="110%">
      <div className="App">
        <ApolloProvider client={client}>
          {loggedIn.length > 0 ? (
            <AuthdView />
          ) : (
            <UnAuthdView />
          )}
          <Section width="100vw" maxWidth="100vw" position="absolute" bottom="0" p="1.5rem" className="footer" asChild>
            <footer>
              <CurrentTime />
            </footer>
          </Section>
        </ApolloProvider>
      </div>
    </Theme>
  );
}

export default App;
