import {
    gql,
    useQuery
} from "@apollo/client";
import { Box, IconButton } from "@radix-ui/themes";

const GET_TIME = gql`
  query {
    getCurrentGMTTime
  }
`;
export function CurrentTime() {
    const { loading, error, data } = useQuery(GET_TIME, { pollInterval: 5000 });

    if (loading) return <p>Loading...</p>;
    if (error) {
        localStorage.removeItem("viewerId");
        // localStorage.deleteItem("authentication");
        return <p>The server is out for lunch</p>;
    }
    // if (localStorage.getItem("authentication") && !data.isValidAuth) {
    //     localStorage.removeItem("authentication");
    //     localStorage.removeItem("viewerId");
    // }
    if (localStorage.getItem("viewerId")) return (
        <Box>
            <div key="currentTime">
                <h3>{data.getCurrentGMTTime}</h3>
            </div>
        </Box>
    );
    return (
        <Box>
            <IconButton>
                <i class="fa-duotone fa-xl fa-binary-lock" />
            </IconButton>
            <div key="currentTime">
                <h3>{data.getCurrentGMTTime}</h3>
            </div>
        </Box>
    );
}
