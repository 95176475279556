import { Section } from "@radix-ui/themes";

import {
    Grid
} from "@radix-ui/themes";

export default function UnAuthdView() {
    return (
        <Grid
            className="grid"
            columns="2"
            minHeight="99vh"
            minWidth="99vw"
            maxWidth="100vw"
            asChild
        >
            <main>
                <Section minWidth="50vw" maxWidth="70vw" px="24px">
                    <Section>
                        <h1>Hi there, login to see more</h1>
                    </Section>
                </Section>
            </main>
        </Grid>

    )
}
