import { Section } from "@radix-ui/themes";
import { CurrentViewerCount } from "./CurrentViewerCount";
import { Icons } from "./Icons";


export default function Nav() {
    return (
        <Section maxWidth="25vw" right="0" position="absolute" asChild>
            <nav>
                <CurrentViewerCount />
                <Icons />
            </nav>
        </Section>
    )
}
