import { gql, useQuery } from "@apollo/client";
import {
    Box, HoverCard,
    IconButton,
    Link, Text
} from "@radix-ui/themes";
export const GET_VIEWER_COUNT = gql`
  query {
    getCurrentViewerCount
  }
`;
export function CurrentViewerCount() {
    const { loading, error, data } = useQuery(GET_VIEWER_COUNT, {
        pollInterval: 30000,
    });

    if (loading) return <p>Loading...</p>;
    if (error)
        return (
            <i
                class="fa-duotone fa-cloud-slash fa-rotate-180 fa-2xl"
                style={{
                    "--fa-primary-color": "#e826be",
                    "--fa-secondary-color": "#e826be",
                }} />
        );

    return (
        <Box width="64px" height="64px">
            <HoverCard.Root>
                <HoverCard.Trigger>
                    <Link href="#">
                        <IconButton>
                            <i class="fa-duotone fa-sparkles fa-rotate-180 fa-2xl" />
                        </IconButton>
                    </Link>
                </HoverCard.Trigger>
                <HoverCard.Content size="2" maxWidth="320px">
                    <Text as="div" size="2" trim="both">
                        {data.getCurrentViewerCount} active viewer(s)
                    </Text>
                </HoverCard.Content>
            </HoverCard.Root>
        </Box>
    );
}
