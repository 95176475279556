
import {
    Grid,
    Section,
} from "@radix-ui/themes";
import Nav from "../../components/Nav";

export default function AuthdView() {
    return (
        <Grid
            className="grid"
            columns="2"
            minHeight="99vh"
            minWidth="99vw"
            maxWidth="100vw"
            asChild
        >
            <main>
                <Section minWidth="50vw" maxWidth="70vw" px="24px">
                    <h1>Hello</h1>
                </Section>
                <Nav />
            </main>
        </Grid>
    )
}
